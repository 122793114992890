<template>
  <layout
    :style="`background: ${$theme.background}`"
    :title="$route.name"
    :options="options"
  >
    <div class="expande-horizontal wrap">
      <BoxInfo :box="get_caixa" />
      <BoxTransactions :box="get_caixa" />
      <!-- <BoxActions :box="get_caixa" /> -->
    </div>
  </layout>
</template>

<script>
import BoxInfo from "@/apps/27#Caixas/components/box-info/Index.vue";
import BoxTransactions from "@/apps/27#Caixas/components/box-transactions/Index.vue";
import BoxActions from "@/apps/27#Caixas/components/box-actions/Index.vue";

import { mapActions, mapGetters } from "vuex";
export default {
  components: { BoxTransactions, BoxInfo, BoxActions },
  props: ["id"],
  data() {
    return {
      options: [
        {
          icon: "mdi-update",
          nome: "Atualizar",
          action: () => this.listar_caixas()
        }
      ]
    };
  },
  computed: {
    ...mapGetters(["get_caixa", "getLoggedUser"])
  },
  methods: {
    ...mapActions(["abre_modal_view_caixa", "fetch_caixa"]),
    imprimir() {
      this.card = false;
      this.$refs.html2Pdf.generatePdf();
    }
  },
  created() {
    this.fetch_caixa(this.id);
  }
};
</script>
