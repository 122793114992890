<template>
  <v-flex xs12 md3>
    <div class="expande-horizontal column fonte info-box-container">
      <div class="expande-horizontal">
        <v-btn icon @click="$router.push('/caixas')">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>

        <div>
          <h3>
            {{ box.id_caixa }} #
            {{ box.funcionario.nome }}
          </h3>
          <span> Este caixa está {{ box.status_atual }} </span>
        </div>
      </div>

      <v-divider class="mt-3"></v-divider>

      <v-list-item class="fonte pa-0 ma-0 mt-3">
        <v-list-item-content>
          <v-list-item-title class="green--text">
            {{ $helper.formataSaldo(box.total_debito | 0) }}
          </v-list-item-title>
          <v-list-item-subtitle>
            Total em Débito
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item class="fonte pa-0 ma-0">
        <v-list-item-content>
          <v-list-item-title class="green--text">
            {{ $helper.formataSaldo(box.total_credito | 0) }}
          </v-list-item-title>
          <v-list-item-subtitle>
            Total em Crédito
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item class="fonte pa-0 ma-0">
        <v-list-item-content>
          <v-list-item-title class="green--text">
            {{ $helper.formataSaldo(box.total_dinheiro | 0) }}
          </v-list-item-title>
          <v-list-item-subtitle>
            Total em Dinheiro
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item class="fonte pa-0 ma-0">
        <v-list-item-content>
          <v-list-item-title class="green--text">
            {{ $helper.formataSaldo(box.total_pix | 0) }}
          </v-list-item-title>
          <v-list-item-subtitle>
            Total em Pix
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </div>
  </v-flex>
</template>

<script>
export default {
  props: {
    box: {
      type: Object,
      required: true,
      default: () => {}
    }
  },
  computed: {
    transactions() {
      return this.box.transactions || [];
    },
    totalDebit() {
      const transactions = this.box.transactions || [];
      let totalValue = 0;
      transactions.map(transaction => {
        if (transaction.compra) {
          if (transaction.compra.debito) {
            totalValue += parseFloat(transaction.compra.debito);
          }
        }
      });
      return totalValue;
    },
    totalCredit() {
      const transactions = this.box.transactions || [];
      let totalValue = 0;
      transactions.map(transaction => {
        if (transaction.compra) {
          if (transaction.compra.credito) {
            totalValue += parseFloat(transaction.compra.credito);
          }
        }
      });
      return totalValue;
    },
    totalMoney() {
      const transactions = this.box.transactions || [];
      let totalValue = 0;
      transactions.map(transaction => {
        if (transaction.compra) {
          if (transaction.compra.dinheiro) {
            totalValue += parseFloat(transaction.compra.dinheiro);
          }
        }
      });
      return totalValue;
    },
    totalPix() {
      const transactions = this.box.transactions || [];
      let totalValue = 0;
      transactions.map(transaction => {
        if (transaction.compra) {
          if (transaction.compra.pix) {
            totalValue += parseFloat(transaction.compra.pix);
          }
        }
      });
      return totalValue;
    }
  }
};
</script>

<style>
.info-box-container {
  border-radius: 5px;
  padding: 12px;
}
</style>
