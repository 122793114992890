<template>
  <v-flex xs12 md9 class="fonte">
    <div class="expande-horizontal pa-3 column">
      <h3>{{ box.transactions.length }} Registros de caixa</h3>
      <span> Confira eles abaixo </span>
    </div>
    <v-divider></v-divider>
    <v-list class="list" three-line style="max-height: 70vh; overflow: auto;">
      <template v-for="transaction in transactions">
        <v-list-item
          @click="abre_modal_view_gestordepedido(transaction)"
          class="item-list"
          :key="transaction._id"
        >
          <v-list-item-content>
            <v-list-item-title class="">
              # {{ transaction.id_transacao }}
            </v-list-item-title>
            <v-list-item-title class="green--text">
              {{ $helper.formataSaldo(transaction.compra.subtotal || 0) }}
            </v-list-item-title>
            <v-list-item-subtitle>
              {{
                transaction.compra.cliente
                  ? transaction.compra.cliente.nome
                  : "Sem cliente"
              }}
            </v-list-item-subtitle>
            <v-list-item-subtitle>
              {{ $moment(transaction.created_at).format("LLLL") }}
            </v-list-item-subtitle>
            <v-list-item-subtitle>
              <v-chip class="mr-1" :color="$theme.secondary" dark x-small>
                {{ transaction.tipo_de_transacao }}
              </v-chip>
              <v-chip class="mr-1" color="green" dark x-small>
                {{ transaction.status_do_pedido || transaction.status_atual }}
              </v-chip>
              <v-chip :color="$theme.primary" dark x-small>
                {{ transaction.compra.tipo }}
              </v-chip>
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-list-item-title>
              {{ transaction.value }}
            </v-list-item-title>
          </v-list-item-action>
        </v-list-item>
      </template>
    </v-list>
    <ViewSale />
  </v-flex>
</template>

<script>
import ViewSale from "@/apps/GestorDePedido/components/ViewSale";
import { mapActions } from "vuex";

export default {
  components: { ViewSale },
  props: {
    box: {
      type: Object,
      required: true,
      default: () => {}
    }
  },
  computed: {
    transactions() {
      return this.box.transactions || [];
    }
  },
  methods: {
    ...mapActions(["abre_modal_view_gestordepedido"])
  }
};
</script>

<style>
.item-list {
  border-bottom: 1px solid #eee;
  margin-bottom: 12px;
}
/* Works on Firefox */
.list {
  scrollbar-width: thin;
  scrollbar-color: rgb(175, 175, 175) rgb(195, 194, 194);
}

/* Works on Chrome, Edge, and Safari */
.list::-webkit-scrollbar {
  width: 12px;
}

.list::-webkit-scrollbar-track {
  background: rgb(228, 228, 227);
}

.list::-webkit-scrollbar-thumb {
  background-color: #010f50;
  border-radius: 20px;
  border: 3px solid rgb(228, 228, 227);
}
</style>
